import React from "react";
import { Route, Switch } from "react-router-dom";
import Anuncio from "./pages/Anuncio";
import CadastroConsumidor from "./pages/CadastroConsumidor";
import CriarMeuEvento from "./pages/CriarMeuEvento";
import Eventos from "./pages/Eventos";
import EventosPublicos from "./pages/EventosPublicos";
import listagemFiltro from "./pages/Filtro/components/listagem";
import Home from "./pages/Home";
import QuemSomosAnunciante from "./pages/QuemSomosAnunciante";
import QuemSomosConsumidor from "./pages/QuemSomosConsumidor";
import CadastroAnunciante from "./pages/cadastroAnunciante";
import Convite from "./pages/convite";
import LoginAnunciante from "./pages/loginAnunciante";
import Login from "./pages/loginConsumidor";
import NotFound from "./pages/notFound";
import QuemSomos from "./pages/quemSomos";
import PoliticaDePrivacidadeApp from "./pages/PoliticaDePrivacidade/PoliticaApp";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route
        path="/politica-privacidade/app"
        exact
        component={PoliticaDePrivacidadeApp}
      />

      <Route path="/filter/:dados" component={listagemFiltro} />

      <Route path="/anuncio/:id/:idAnunciante" component={Anuncio} />
      <Route path="/evento-publico/:id" exact component={Eventos} />
      <Route path="/eventos-publicos" component={EventosPublicos} />
      <Route path="/login-consumidor" component={Login} />
      <Route path="/login-anunciante" component={LoginAnunciante} />
      <Route path="/convite" component={Convite} />
      <Route path="/cadastro-anunciante" component={CadastroAnunciante} />
      <Route path="/cadastro-consumidor" component={CadastroConsumidor} />
      <Route path="/quem-somos" component={QuemSomos} />
      <Route path="/quem-somos-consumidor" component={QuemSomosConsumidor} />
      <Route path="/quem-somos-anunciante" component={QuemSomosAnunciante} />
      <Route path="/criar-meu-evento" component={CriarMeuEvento} />

      <Route path="*" component={NotFound} />
    </Switch>
  );
}

